import { Button, Checkbox, FormikElementWrapper, Icon, Input } from '@features/ui/components';
import { Password } from '@features/ui/components/Password';
import { useAutoFocus } from '@features/ui/hooks/useAutoFocus';
import { AuthApi, useApi } from '@services/api';
import { Form, Formik, FormikConfig } from 'formik';
import { useTranslation } from 'next-i18next';

// In case the type changes
export type LoginFormValues = { password: string; rememberMe: boolean; username: string };
export type UseLoginParameter = Required<Parameters<typeof AuthApi.prototype.useLogin>>[0];

export type LoginFormProps = Required<Pick<UseLoginParameter, 'onSuccess'>> &
  UseLoginParameter &
  Partial<Pick<FormikConfig<LoginFormValues>, 'initialValues'>>;

export const LoginForm = ({
  initialValues,
  onError = () => undefined,
  onSuccess,
  ...props
}: LoginFormProps): JSX.Element => {
  const { authApi } = useApi();
  const { t } = useTranslation('common');

  const useLogin = authApi.useLogin({ onError, onSuccess });

  const autoFocusRef = useAutoFocus();

  return (
    <Formik<LoginFormValues>
      initialValues={initialValues || { username: '', password: '', rememberMe: true }}
      validateOnBlur={false}
      onSubmit={values => useLogin.mutate(values)}
      validate={values => {
        const errors: Partial<Record<keyof LoginFormValues, string>> = {};

        for (const fields of ['username', 'password'] as Array<keyof LoginFormValues>) {
          if (!values[fields]) {
            errors[fields] = t('errors.field.required');
          }
        }

        return errors;
      }}
      {...props}
    >
      <Form className="flex flex-col gap-y-5">
        <FormikElementWrapper<LoginFormValues> label={t('pages.login.username')} name="username">
          <Input type="text" ref={autoFocusRef} />
        </FormikElementWrapper>

        <FormikElementWrapper<LoginFormValues> label={t('entities.user.password')} name="password">
          <Password />
        </FormikElementWrapper>

        <FormikElementWrapper<LoginFormValues> inline name="rememberMe">
          <Checkbox>{t('pages.login.remember-me')}</Checkbox>
        </FormikElementWrapper>

        {useLogin.status === 'error' && (
          <div className="flex flex-row gap-x-2 text-red-400" id="loginError">
            <Icon icon="warning" className="mt-1" />
            <span>
              {useLogin.error?.response?.status === 401 ? t('pages.login.errors.401') : t('errors.error-occurred')}
            </span>
          </div>
        )}

        <Button
          label={t('pages.login.login')}
          leftIcon="userDoctor"
          loading={useLogin.status === 'loading'}
          type="submit"
          className="font-medium"
        />
      </Form>
    </Formik>
  );
};

import { Button } from '@features/ui/components';
import { Header } from '@features/ui/components/layout/header/Header';
import { envConfig } from '@root/envConfig';
import { useTranslation } from 'next-i18next';

export const LoginHeaderRight = (): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <div className="h-10">
      <Button
        label={t('pages.login.header.back')}
        leftIcon="arrowTurnDownLeft"
        url={envConfig.info.site}
        variant="transparent-outline"
      />
    </div>
  );
};

export const LoginHeader = (): JSX.Element => <Header right={<LoginHeaderRight />} />;

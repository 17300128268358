import classNames from 'classnames';
import { ReactNode } from 'react';

const variantStyles = {
  blue: 'text-sky-500 bg-sky-50',
} as const;

export type FabVariant = keyof typeof variantStyles;

export type FabProps = {
  children: ReactNode;
  className?: string;
  variant: FabVariant;
};

export const Fab = ({ children, variant, className }: FabProps): JSX.Element => {
  const variantStyle = variantStyles[variant];

  return (
    <span
      className={classNames(
        variantStyle,
        'inline-flex h-12 w-12 items-center whitespace-nowrap rounded-lg px-3 py-3 text-xl',
        className,
      )}
    >
      {children}
    </span>
  );
};

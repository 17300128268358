import { LoginHeader } from '@features/login/headers';
import { LoginTemplate } from '@features/login/LoginTemplate';
import { Layout } from '@features/ui/components';
import { NextPageWithLayout } from '@features/ui/types/NextPageWithLayout';
import staticProps from '@services/staticProps';

export const LoginPage: NextPageWithLayout = (): JSX.Element => <LoginTemplate />;

LoginPage.getLayout = page => <Layout header={<LoginHeader />}>{page}</Layout>;

export default LoginPage;

export const getStaticProps = staticProps;

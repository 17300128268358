import { MutableRefObject, useEffect, useRef } from 'react';

export const useAutoFocus = (): MutableRefObject<HTMLInputElement | null> => {
  const autoFocusRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    autoFocusRef?.current?.focus();
  }, []);

  return autoFocusRef;
};

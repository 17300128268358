import { LoginContentHeader } from '@features/login/components';
import { IfNotLoggedIn } from '@features/login/components/IfNotLoggedIn';
import { LoginHelpText } from '@features/login/components/LoginHelpText';
import { LoginForm } from '@features/login/forms/LoginForm';
import { Card } from '@features/ui/components/Card';
import { LinkInternal } from '@features/ui/components/LinkInternal';
import { getRoute, LoginRouteQuery } from '@services/routes';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

export const LoginTemplate = (): JSX.Element => {
  const router = useRouter();
  const { t } = useTranslation('common');

  const goSite = () => {
    const redirectUrl = (router.query as LoginRouteQuery)?.redirectUrl;
    (redirectUrl ? router.replace(redirectUrl) : router.replace(getRoute.home())).catch(() => undefined);
  };

  return (
    <>
      <LoginContentHeader />

      <div className="flex flex-col items-center gap-4 md:flex-row md:items-start">
        <div>
          <Card className="max-w-[412px] p-10 sm:mx-6 lg:mx-20 xl:mx-32" header={t('pages.login.title-connect')}>
            <div className="flex flex-col gap-y-4">
              <IfNotLoggedIn ifLoggedIn={goSite}>
                <LoginForm onSuccess={goSite} />

                <div className="flex">
                  <LinkInternal href={getRoute.passwordForget()} className="text-sm font-medium" underline={false}>
                    {t('pages.login.password-forget')}
                  </LinkInternal>
                </div>
              </IfNotLoggedIn>
            </div>
          </Card>
        </div>

        <LoginHelpText className="flex-1 p-10 md:mt-28 md:p-0" />
      </div>
    </>
  );
};

import { Icon } from '@features/ui/components';
import { Fab } from '@features/ui/components/Fab';
import { LinkExternal } from '@features/ui/components/LinkExternal';
import { envConfig } from '@root/envConfig';
import classNames from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import { HTMLAttributes } from 'react';

export type LoginHelpTextProps = Pick<HTMLAttributes<HTMLDivElement>, 'className'>;

export const LoginHelpText = ({ className }: LoginHelpTextProps): JSX.Element => {
  const { t } = useTranslation('common');

  const { mail, phone, site } = envConfig.info;

  return (
    <div
      className={classNames(
        'text-brand-black flex max-w-[600px] flex-col items-center gap-4 text-lg md:items-start',
        className,
      )}
    >
      <div>
        <Fab variant="blue">
          <Icon icon="messageQuestion" />
        </Fab>
      </div>

      <p>
        <Trans
          components={{
            a: <LinkExternal aria-label="site" href={site} />,
          }}
          i18nKey="pages.login.help-text.p1"
          t={t}
        />
      </p>

      <p>{t('pages.login.help-text.p2')}</p>

      <p>
        <Trans
          components={{
            'a-mail': <LinkExternal aria-label="mail" href={`mailto:${mail}`} />,
            'a-phone': <LinkExternal aria-label="phone" href={`tel:${phone}`} />,
          }}
          i18nKey="pages.login.help-text.p3"
          t={t}
          values={{ mail, phone }}
        />
      </p>
    </div>
  );
};

import { Button } from '@features/ui/components';
import { useApi } from '@services/api';
import { Trans, useTranslation } from 'next-i18next';
import { ReactNode, useEffect, useState } from 'react';

export type IfNotLoggedInProps = {
  /**
   * Components that will be shown only if the user is not already logged in
   */
  children: ReactNode;

  /**
   * Function that will be called if the user is already logged in
   */
  ifLoggedIn: () => void;
};
export const IfNotLoggedIn = ({ children, ifLoggedIn }: IfNotLoggedInProps): JSX.Element => {
  const { t } = useTranslation('common');
  const { authApi } = useApi();
  const [logged, setLogged] = useState(false);

  // Do not redirect on 401
  const getMe = authApi.useGetMe({ onError: () => undefined });

  useEffect(() => {
    setLogged(getMe.isSuccess);
  }, [getMe.isSuccess]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (logged) {
      timeout = setTimeout(() => ifLoggedIn(), 5000);
    }

    return () => timeout && clearTimeout(timeout);
  });

  return logged ? (
    <>
      <h3 className="text-xl">
        <Trans
          components={{ n: <span className="text-primary" /> }}
          i18nKey="pages.login.hello-logged.title"
          t={t}
          values={{ name: getMe.data?.name }}
        />
      </h3>

      <p>{t('pages.login.hello-logged.text')}</p>

      <Button label={t('pages.login.to-home')} onClick={ifLoggedIn} />
    </>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

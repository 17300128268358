import classNames from 'classnames';
import Link from 'next/link';
import { AnchorHTMLAttributes } from 'react';
import { UrlObject } from 'url';

export type LinkInternalProps = Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'children' | 'className'> & {
  href: string | UrlObject;
  underline?: boolean;
};

/**
 * To use when the site redirects to an internal link.
 */
export const LinkInternal = ({ underline, ...props }: LinkInternalProps): JSX.Element => (
  <Link
    {...props}
    className={classNames(
      'text-primary',
      'font-bold',
      props.className,
      underline === true || underline === undefined ? 'underline' : '',
    )}
  >
    {props.children}
  </Link>
);

import classNames from 'classnames';
import { AnchorHTMLAttributes } from 'react';

export type LinkExternalProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'rel' | 'target'>;

/**
 * To use when the site redirects to an external links.
 * It opens on a new tab.
 */
export const LinkExternal = (props: LinkExternalProps): JSX.Element => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <a
    {...props}
    className={classNames('text-primary font-bold underline', props.className)}
    rel="noreferrer"
    target="_blank"
  />
);

import { Input, InputProps } from '@features/ui/components';
import { forwardRef, useState } from 'react';

export type PasswordProps = Omit<InputProps, 'iconRight' | 'type'>;

export const Password = forwardRef<HTMLInputElement, PasswordProps>((props: PasswordProps, ref): JSX.Element => {
  const [show, setShow] = useState(false);

  return (
    <Input
      {...props}
      iconRight={{
        icon: show ? 'eyeSlash' : 'eye',
        onClick: props.disabled ? undefined : () => setShow(!show),
      }}
      type={show ? 'text' : 'password'}
      ref={ref}
    />
  );
});
